import { defineStore } from 'pinia';
import {
  login as userLogin,
  logout as userLogout,
  getUserProfile,
  sendCode,
  help,
  register,
  resetPwd,
  getUserInfoByAccessToken,
} from '@/api/userInfo';
import {
  userEquity as bUserEquity,
  getCurrentSubscription as bGetCurrentSubscription,
} from '@/api/premium';
import {
  userEquity as sUserEquity,
  getCurrentSubscription as sGetCurrentSubscription,
} from '@/api/svPremium';
import {
  setToken,
  clearToken,
  setEmail,
  setUsername,
  setAvatar,
  isLogin,
} from '@/utils/auth';
import { apiCall } from '@/utils/request';
import { getCloudDetail } from '@/api/space';
import { identifyUser } from '@/utils/track';
import { useMessage } from "@/utils";
import { getItem, setItem } from '@/utils/storage';

function initSubscriptionInfo(): {
  subscriptionState: ResData.GetCurrentSubscription;
} & ResData.UserEquity {
  return {
    dueTime: 0,
    packageCode: -1,
    packageType: '',
    paymentPlatform: '',
    userResourceList: [],
    subscriptionState: {
      cancelSubAtPeriodEndBoolean: false,
      currentPeriodPaymentStatus: 'paid',
      currentPlanName: '',
      currentSubCreateTime: 0,
      supportRefundBoolean: false,
      nextRenewalDateTime: 0,
      packageCode: -1,
      trialingBoolean: false,
    },
  };
}

export const useSubscriptionInfo = defineStore('subscriptionInfo', {
  state: function () {
    return initSubscriptionInfo();
  },
  actions: {
    async refresh() {
      const globalDomain = getItem('currentDomain');
      if (!isLogin.value) {
        return this.init();
      } else {
        const userEquity = async () => {
          switch (globalDomain) {
            case 1:
              return await bUserEquity();
            case 2:
              return await sUserEquity();
          }
        };
        const { data = this.init() } = await userEquity();

        for (const item of data.userResourceList) {
          item.resourceCode = item.resourceCode.toUpperCase();
        }

        this.$patch(data);
        this.updateIdentity();
        return data;
      }
    },
    init() {
      const info = initSubscriptionInfo();
      this.$patch(info);
      return info;
    },
    updateIdentity() {
      identifyUser(localStorage.getItem('user_email'), {
        package_code: this.packageCode.toString(),
      });
    },
    getUserResource(resourceCode: string) {
      const uniqueList = () => {
        let map = new Map();
        for (const item of this.userResourceList) {
          if (!map.has(item.resourceCode)) {
            map.set(item.resourceCode, item);
          } else {
            const newItem = { ...map.get(item.resourceCode) };
            // unlimited
            if (newItem.num === -1) continue;
            newItem.num += item.num;
            map.set(item.resourceCode, newItem);
          }
        }
        return [...map.values()];
      };
      const usedCode = resourceCode.toUpperCase();
      const resourceList = uniqueList();
      return resourceList.find((item) => item.resourceCode === usedCode);
    },
    async updateSubscriptionState() {
      const checkCode = (pc: number) => {
        let res = pc;
        if (pc === 300101) {
          res = -1;
        }
        return res;
      };
      try {
        const globalDomain = getItem('currentDomain');
        const getCurrentSubscription = async () => {
          switch (globalDomain) {
            case 1:
              return await bGetCurrentSubscription();
            case 2:
              return await sGetCurrentSubscription();
          }
        };
        const { data, code } = await getCurrentSubscription();
        if (code === 0) {
          const newSubscriptionState = {
            ...data,
            packageCode: checkCode(data.packageCode),
          };
          this.subscriptionState = newSubscriptionState;
        }
      } catch (err) {
        const message = useMessage();
        message.error('Failed to fetch current state');
        this.subscriptionState = { ...this.subscriptionState, packageCode: -1 };
      }
    },
  },
});

export const useUserStore = defineStore('user', {
  // other options...
  state: () => {
    return {
      token: '',
      userName: localStorage.getItem('user_name'),
      email: localStorage.getItem('user_email'),
      hasCredit: null,
      loginMsg: '',
    };
  },
  getters: {},
  actions: {
    // 获取用户信息
    async info() {
      const result: any = await getUserProfile();
      this.setInfo(result);
    },
    //获取用户权益
    async userSubscription() {},
    //获取用户订阅套餐
    /**
     * @deprecated 需从useSubscriptionInfo中获取相关数据或直接使用请求接口
     */
    async subscribeState() {
      const subscriptionInfo = useSubscriptionInfo();
      await subscriptionInfo.refresh();
    },
    // 设置用户的信息
    setInfo(partial: Object) {
      this.$patch(partial);
    },
    // 重置用户信息
    resetInfo() {
      this.$reset();
    },
    // 异步登录并存储token
    async userLogin(loginForm: { password: string; username: string }) {
      const result: any = await userLogin(loginForm);
      if (result.code === 0) {
        const token = result.data.token;
        if (token) {
          setEmail(result.data.email);
          setUsername(result.data.username);
          setToken(token);
          setItem("skipPortraitValue", false);
          this.userName = result.data.username;
          this.setInfo(result.data);

          identifyUser(localStorage.getItem('user_email'), {
            email: localStorage.getItem('user_email') ?? '',
            username: localStorage.getItem('user_name') ?? '',
          });
        }
      }
      return result;
    },
    // google登录并存储token
    async googleLogin(accessToken: Object) {
      const result: any = await getUserInfoByAccessToken(accessToken);
      if (result.code === 0) {
        const token = result.data.token;
        if (token) {
          setToken(token);
          setEmail(result.data.email);
          setUsername(result.data.username);
          setAvatar(result.data.picture);
          setItem("skipPortraitValue", false);
          this.userName = result.data.username;
          this.email = result.data.email;
          this.setInfo(result.data);

          identifyUser(localStorage.getItem('user_email'), {
            email: localStorage.getItem('user_email') ?? '',
            username: localStorage.getItem('user_name') ?? '',
          });
        }
      }
      return result;
    },

    // Logout
    async logout() {
      const res = await userLogout();
      // 退出登录后;
      this.resetInfo();
      clearToken();
      if (this.router.currentRoute.value.meta.isAuth) {
        this.router.push({
          path: '/login',
          query: { redirect: this.router.currentRoute.value.fullPath },
        });
      }
      return res;
    },

    //发送验证码
    async sendEmail(email: Object) {
      try {
        const result = await sendCode(email);
        return result;
      } catch (error) {
        return error;
      }
    },
    //注册
    async register(params) {
      const result: any = await register(params);
      if (result.code === 0) {
        const token = result.data.token;
        if (token) {
          setToken(token);
          setEmail(result.data.email);
          setUsername(result.data.username);
          this.userName = result.data.username;
          this.setInfo(result);
          if (window.gr) {
            window.gr('track', 'conversion', { email: result.data.email });
          }

          const globalDomain = getItem('currentDomain');
          // 联盟营销
          if (window.trackdesk) {
            window.trackdesk('similarvideo-boolv', 'externalCid', {
              externalCid: result.data.email,
              revenueOriginId: 'b0aa776a-2476-4cb4-9c45-2717d37bef7f',
            });
          }

          gtag('event', 'registered_success', {
            email: result.data.email,
          });

          identifyUser(localStorage.getItem('user_email'), {
            email: localStorage.getItem('user_email') ?? '',
            username: localStorage.getItem('user_name') ?? '',
            signup_time: new Date(),
          });
        }
      }
      return result;
    },
    //重置密码，发送邮件
    async help(params: { email: string }) {
      const result: any = await help(params);
      return result;
    },
    //更改密码
    async resetPwd(params: { email: string; password: string; state: string }) {
      return await resetPwd(params);
    },
  },
});

export const useSpaceStore = (() => {
  let refreshState = false;

  return defineStore('spaceInfo', {
    state: () => ({
      totalSize: 0,
      usedSize: 0,
    }),
    actions: {
      async refresh() {
        if (refreshState) return;

        apiCall(getCloudDetail)
          .then(({ total, used }) => {
            this.$patch({ totalSize: total, usedSize: used });
          })
          .finally(() => {
            refreshState = false;
          });

        refreshState = true;
      },
    },
  });
})();
